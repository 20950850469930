<template>
  <div>
    <div class="container">
      <el-row>
        <el-col :span="12">
          <div class="logo">
            <img src="../assets/img/newerp_logo_w.png" alt="" />
            <span>聚知优ERP企业管理系统</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="logo-r">
            <img src="../assets/img/nascc_registration_uplogo.png" alt="" />
          </div>
        </el-col>
      </el-row>
      <div class="bottom-bg">
        <div class="main">
          <div class="flex">
            <div class="item testyx">
              <div class="title">| 英文拼词大会 · ERP管理系统</div>
              <div class="btn">
                <a :href="erpUrlObj.erpUrl1" target="_blank"
                  ><img src="../assets/img/newerp_button1.png" alt=""
                /></a>
              </div>
              <div class="text">
                <img src="../assets/img/newerp_webicon.png" alt="" /> 官网：<a
                  href="http://www.spbcn.org/"
                  target="_blank"
                  >www.spbcn.org</a
                >
              </div>
              <div class="qr"><img src="../assets/img/1.jpg" alt="" /></div>
            </div>
            <div class="item">
              <div class="title">| 汉英双拼大会 · ERP管理系统</div>
              <div class="btn">
                <a :href="erpUrlObj.erpUrl2" target="_blank"
                  ><img src="../assets/img/newerp_button2.png" alt=""
                /></a>
              </div>
              <div class="text">
                <img src="../assets/img/newerp_webicon.png" alt="" /> 官网：<a
                  href="http://www.cebso.org/"
                  target="_blank"
                  >www.cebso.org</a
                >
              </div>
              <div class="qr"><img src="../assets/img/2.jpg" alt="" /></div>
            </div>
            <div class="item">
              <div class="title">| 环球文遗小使者 · ERP管理系统</div>
              <div class="btn">
                <a :href="erpUrlObj.erpUrl3" target="_blank"
                  ><img src="../assets/img/newerp_button3.png" alt=""
                /></a>
              </div>
              <div class="text">
                <img src="../assets/img/newerp_webicon.png" alt="" /> 官网：<a
                  href="http://www.ghacedu.com/"
                  target="_blank"
                  >www.ghacedu.com</a
                >
              </div>
              <div class="qr"><img src="../assets/img/3.jpg" alt="" /></div>
            </div>
          </div>
          <div class="footer">
            <img src="../assets/img/nascc_registration_downlogo.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'navmenu',
  data () {
    return {

    }
  },
  computed: {
    erpUrlObj () {
      // const prefix = window.location.href.includes('localhost') || window.location.href.includes('test') ? 'test.' : ''
      const prefix = process.env.NODE_ENV === 'production' ? '' : 'test.'
      // const newPrefix = process.env.NODE_ENV === 'production' ? 'new.' : 'news.'
      return {
        erpUrl1: process.env.NODE_ENV === 'development' ? `http://${window.location.host}/#/login` : `http://${prefix}erp.spbcn.org/#/login`,
        // erpUrl1: process.env.NODE_ENV === 'development' ? `http://${window.location.host}/#/login` : `http://erp.${newPrefix}spbcn.org/#/login`,
        erpUrl2: `http://${prefix}cebso.spbcn.org/`,
        erpUrl3: `http://${prefix}erp.ghacedu.spbcn.org/`
      }
    }
  },
  created () {
    document.title = '聚知优ERP企业管理系统'
    document.querySelector('#favicon').href = 'favicon_jzy.ico'
    console.log(process.env.NODE_ENV)
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  min-height: 680px;
  background-color: #b21723;
  position: relative;
}
.bottom-bg {
  background-color: #000;
  background-image: url(../assets/img/newerp_bg20.png);
  background-position: bottom right;
  background-size: auto 90%;
  background-repeat: no-repeat;
  width: 100%;
  height: 62%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}
.logo {
  padding-left: 30px;
  padding-top: 30px;
  display: flex;
  align-items: flex-end;
  img {
    width: 80px;
  }
  span {
    color: #000;
    font-size: 20px;
    margin-left: 10px;
  }
}
.logo-r {
  text-align: right;
  margin-right: 60px;
  margin-top: 78px;
  img {
    height: 22px;
  }
}
.main {
  position: absolute;
  top: -80px;
  left: 120px;
  right: 100px;
  bottom: 0;
}
.flex {
  display: flex;
  justify-content: space-around;
}
.footer {
  text-align: center;
  position: absolute;
  bottom: 30px;
  width: 100%;
  img {
    height: 26px;
  }
}
.item {
  width: 30%;
}
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}
.btn {
  width: 100%;
  height: 110px;
  border: 2px solid #000;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b21723;
  cursor: pointer;
  img {
    height: 62px;
  }
  &:hover {
    background: #fa4d5b;
  }
}
.text {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  img {
    margin-right: 10px;
    width: 28px;
  }
}
.qr {
  width: 140px;
  margin: 40px auto 0;
  padding: 12px;
  background: #fff;
  border-radius: 100%;
  img {
    width: 100%;
    border-radius: 100%;
  }
}
</style>
